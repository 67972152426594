import {useEffect, useState} from 'react';
import {useInstance} from 'react-ioc';
import {RightOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import { Button, Form} from 'antd'
import {InputOTP} from 'antd-input-otp';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';

import {getSession} from '../../hooks/getSession';
import {Store} from '../../model/store/Store';
import {
    checkCodeGetMoneyKZ,
    checkCodeGetMoneyKZVariables
} from '../../shared/graphql/__generated__/checkCodeGetMoneyKZ';
import {CHECK_CODE} from '../../shared/graphql/checkCodeGetMoneyKZ';
import {codeRegex} from '../../shared/helpers/regex';

import cls from './Registration.module.scss'

interface ICodeValues {
    code: string[];
}

interface ICode {

    onComplete: (values: ICodeValues) => void
    onBack: () => void

}

function stringifyCode(input: string[]) {
    return input.join('')
}

export const Code = observer(({onComplete, onBack}: ICode) => {

    const [form] = Form.useForm()

    const [checkCode] = useMutation<checkCodeGetMoneyKZ, checkCodeGetMoneyKZVariables>(CHECK_CODE)
    const [invalid, setInvalid] = useState<boolean>(false)

    const {auth} = useInstance(Store)

    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onFinish = async (values: ICodeValues) => {
        await checkCode({
            variables: {
                session: getSession(),
                data: {
                    phone: auth.phone!,
                    code: stringifyCode(values.code),
                }
            },
            onCompleted: (data) => {
                auth.setAuth(true, data.checkCodeGetMoneyKZ?.token!)
                auth.setData(data.checkCodeGetMoneyKZ?.FIO!, auth.phone, null, data.checkCodeGetMoneyKZ?.paid!)
                onComplete(values)
            },
            onError: (error) => {
                // console.log(error.message)
                if (error.message === 'Invalid code') {
                    setInvalid(true)
                }
            }
        })
    }

    const awaitSeconds = dayjs(auth.SendingDate).diff(dayjs().add(-5, 'minutes'), 'seconds')
    console.log(time)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}
              className={cls.code}>


            <Form.Item name={'code'} label={'Код из SMS'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {
                    validator: async (_, value) => {
                        // console.log(value)
                        if (codeRegex.test(stringifyCode(value))) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject(new Error('Не подходящий формат'))
                        }
                    }
                }
            ]}
                       validateStatus={invalid ? 'error' : undefined}
                       help={invalid ? 'Не верный код' : undefined}
            >
                <InputOTP autoSubmit={form} inputType="numeric" length={4}/>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} icon={<RightOutlined/>}>Далее</Button>
            </Form.Item>

            {invalid &&
                <div className={'caption'}>
                    {awaitSeconds > 0
                        ?
                            `Отправить повторно через ${Math.floor(awaitSeconds/60)}:${awaitSeconds%60}`
                        :
                            <div onClick={onBack}>Отправить повторно</div>
                    }
                </div>
            }

        </Form>
    )
})