import {gql} from '@apollo/client';

export const QUERY_SHOWCASE = gql`
    query getMoneyKzOffers ($token: String!) {
        getMoneyKzOffers(token: $token) {
            id
            attributes {
                headline
                percent
                term
                age
                logo {
                    data {
                        id
                        attributes {
                            url
                        }
                    }
                }
                URL
            }
        }
    }
`