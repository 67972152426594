import React, {FC, useEffect} from 'react';
import {useInstance} from 'react-ioc';
import {useSearchParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';
import Cookies from 'universal-cookie';

import {Store} from '../../model/store/Store';
import {visitGetMoneyKz, visitGetMoneyKzVariables} from '../../shared/graphql/__generated__/visitGetMoneyKz';
import {MUTATION_VISIT} from '../../shared/graphql/visit';

import {Advantages} from './Advantages/Advantages';
import {Banner} from './Banner/Banner';
import {GetMoney} from './GetMoney/GetMoney';
import {Instruction} from './Instruction/Instruction';
import {Cards} from './Offers/Cards';

export const Index: FC = observer(() => {

    const {auth} = useInstance(Store)
    const [searchParams] = useSearchParams();
    const [visit] = useMutation<visitGetMoneyKz, visitGetMoneyKzVariables>(MUTATION_VISIT)


    useEffect(() => {

        const cookies = new Cookies();
        let session = undefined

        // если присли по ссылке с click_id
        if (searchParams.get('click_id')) {
            session = searchParams.get('click_id')
            // если нет куки или она другая - делаем новую
            if (!cookies.get('click_id') || cookies.get('click_id') !== searchParams.get('click_id')) {
                // создаеи куку
                cookies.set('click_id', session, {path: '/', maxAge: 1209600});
            }

            // если есть кука - то ставим сессию оттуда
        } else if (cookies.get('click_id')) {
            session = cookies.get('click_id')
        }

        // если есть session = шлем визит
        if (session) {
            visit({
                variables: {
                    session: session!,
                    provider: searchParams.get('utm_source'),
                    token: auth.token,
                },
            }).then()
        }

    }, [auth.token, searchParams, visit])

    return (
        <div className={cn(['content'])}>
            {auth.auth
                ?
                <Cards/>
                :
                <Banner/>
            }
            <Advantages/>
            <GetMoney/>
            <Instruction/>
            {/*<Protection/>*/}
        </div>
    )
})



