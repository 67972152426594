import {useInstance} from 'react-ioc';
import {Link, useNavigate} from 'react-router-dom';
import { LogoutOutlined} from '@ant-design/icons';
import {Button, Dropdown} from 'antd';
import {observer} from 'mobx-react-lite';

import {INDEX, LOGIN, REGISTRATION} from '../../config/routes';
import {Store} from '../../model/store/Store';

import logo from './GetMoneyLogo.svg';

import cls from './Header.module.scss';


const Header = observer(() => {

    const navigate = useNavigate()

    const {auth} = useInstance(Store)

    return (
        <div className={cls.header}>
            <div className={cls.top}>
                <div>
                    <Link to={INDEX}>
                        <img src={logo} alt={'Get Money Logo'} className={cls.logo}/>
                    </Link>
                </div>
                <div className={cls.control}>
                    {auth.auth
                        ?
                        <Dropdown menu={{items: [{key: 'exit', label: 'Выход', onClick: () => auth.clearState()}]}}>
                            <Button size={'large'} type={'text'} icon={<LogoutOutlined/>}>
                                {auth.FIO}
                            </Button>
                        </Dropdown>
                        :
                        <>
                            <Button size={'large'} onClick={() => navigate(LOGIN)}>Вход</Button>
                            <Button size={'large'} onClick={() => navigate(REGISTRATION)}>Регистрация</Button>
                        </>
                    }
                    {/*<Button size={'large'} icon={<MenuOutlined/>}/>*/}

                </div>
            </div>
        </div>
    )
})

export default Header;