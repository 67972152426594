import {gql} from '@apollo/client';

export const QUERY_CITIES = gql`
    query citiesKz ($filters: CityKzFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $locale: I18NLocaleCode) {
        citiesKz(filters: $filters, pagination: $pagination, sort: $sort, locale: $locale) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`