import {gql} from '@apollo/client';

export const CREATE_CODE = gql`
    mutation createGetMoneyKzCode ($data: GetMoneyKzCodeInput!, $phone: String!) {
        createGetMoneyKzCode(data: $data, phone: $phone) {
            data {
                id
            }
        }
    }
`