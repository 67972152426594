import {gql} from '@apollo/client';

export const CHECK_CODE = gql`
    mutation checkCodeGetMoneyKZ ($data: CheckCodeGetMoneyKZInput!, $session: String) {
        checkCodeGetMoneyKZ(data: $data, session: $session) {
            FIO
            token
            paid
        }
    }
`