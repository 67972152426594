import {useEffect} from 'react';
import {useInstance} from 'react-ioc';
import {useQuery} from '@apollo/client';
import {observer} from 'mobx-react-lite';

import {Store} from '../../model/store/Store';
import {getMoneyKzMe, getMoneyKzMeVariables} from '../../shared/graphql/__generated__/getMoneyKzMe';
import {QUERY_ME} from '../../shared/graphql/me';

export const Me = observer(() => {

    const {auth} = useInstance(Store)
    const query = useQuery<getMoneyKzMe,getMoneyKzMeVariables>(QUERY_ME, {
        variables: {token: auth.token!},
        pollInterval: 10000,
    })
    
    useEffect(() => {
        auth.setData(query.data?.getMoneyKzMe?.FIO!, auth.phone, null, query.data?.getMoneyKzMe?.paid!)
    }, [auth, query.data?.getMoneyKzMe?.FIO, query.data?.getMoneyKzMe?.paid])

    return null

})