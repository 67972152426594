import {gql} from '@apollo/client';

export const QUERY_ORDER = gql`
    query getMoneyKzOrder ($UID: ID, $token: String!) {
        getMoneyKzOrder(UID: $UID, token: $token) {
            data {
                id
                attributes {
                    UID
                    status
                }
            }
        }
    }
`