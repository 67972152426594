import { useState} from 'react';
import {useInstance} from 'react-ioc';
import { Link } from 'react-router-dom';
import {RightOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {AutoComplete, Button, Checkbox, DatePicker, Form, Input, Radio} from 'antd'
import {MaskedInput} from 'antd-mask-input';
import dayjs from 'dayjs'
import _ from 'lodash';
import {observer} from 'mobx-react-lite';

import {ENUM_GETMONEYKZREG_SEX} from '../../__generated__/global-types';
import {DOC_OFFER, DOC_POLICY, LOGIN} from '../../config/routes';
import {getSession} from '../../hooks/getSession';
import {Store} from '../../model/store/Store';
import {citiesKz, citiesKzVariables} from '../../shared/graphql/__generated__/citiesKz';
import {
    createGetMoneyKzCode,
    createGetMoneyKzCodeVariables
} from '../../shared/graphql/__generated__/createGetMoneyKzCode';
import {
    createGetMoneyKzReg,
    createGetMoneyKzRegVariables
} from '../../shared/graphql/__generated__/createGetMoneyKzReg';
import {QUERY_CITIES} from '../../shared/graphql/cities';
import {CREATE_CODE} from '../../shared/graphql/createGetMoneyKzCode';
import {CREATE_REG} from '../../shared/graphql/createGetMoneyKzReg';
import {phoneKzRegex} from '../../shared/helpers/regex';

import cls from './Registration.module.scss'

interface IFormValues {
    FIO: string;
    phone: string;
    birthday: string;
    sex: 'male' | 'female';
    city: string;
}

interface IForm {

    onComplete: (values: IFormValues) => void

}

export const RegForm = observer(({onComplete}: IForm) => {

    const [form] = Form.useForm()
    const [agreePolicy, setAgreePolicy] = useState<boolean>(false)
    const [agreeOffer, setAgreeOffer] = useState<boolean>(false)

    const [errorUniqyePhone, setErrorUniqyePhone] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')

    const {auth} = useInstance(Store)

    const [createReg, createRegData] = useMutation<createGetMoneyKzReg, createGetMoneyKzRegVariables>(CREATE_REG)
    const [createCode, createCodeData] = useMutation<createGetMoneyKzCode, createGetMoneyKzCodeVariables>(CREATE_CODE)
    const cities = useQuery<citiesKz, citiesKzVariables>(QUERY_CITIES, {
        variables: {
            locale: 'ru',
            pagination: {limit: 100},
        },
    })
    const citiesOptions = _.map(cities.data?.citiesKz?.data, city => ({
        label: city.attributes?.name,
        value: city.attributes?.name
    })).filter(item => item.value?.includes(search))

    const onFinish = async (values: IFormValues) => {
        await createReg({
            variables: {
                session: getSession(),
                data: {
                    FIO: values.FIO,
                    phone: values.phone,
                    birthday: dayjs(values.birthday).format('YYYY-MM-DD'),
                    sex: values.sex === 'male' ? ENUM_GETMONEYKZREG_SEX.male : ENUM_GETMONEYKZREG_SEX.female,
                    city: values.city,
                }
            },
            onCompleted: async () => {
                auth.setData(values.FIO, values.phone, dayjs().format(), false)
                await createCode({
                    variables: {
                        phone: values.phone,
                        data: {},
                    },
                    onCompleted: () => {
                        onComplete(values)
                    }
                })
            },
            onError: (error) => {
                console.log(error.message)
                if (error.message === 'This attribute must be unique') {
                    setErrorUniqyePhone(true)
                }
            }
        })
    }

    const options = [
        {label: 'Мужской', value: 'male'},
        {label: 'Женский', value: 'female'},
    ];

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}
              className={cls.form}>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'phone'} label={'Номер телефона'} validateFirst rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneKzRegex, message: 'Не соответствует формату номера телефона'},
                {validator: () => {
                        if (errorUniqyePhone) {
                            return Promise.reject('Такой номер уже занят')
                        } else {
                            return Promise.resolve()
                        }
                }},
            ]}
                       // validateStatus={errorUniqyePhone ? 'error' : undefined} help={errorUniqyePhone && 'Такой номер уже занят'}
            >
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (700) 000-00-00'} size={'large'} status={errorUniqyePhone ? 'error' : undefined}/>
            </Form.Item>
            {errorUniqyePhone &&
                <Form.Item>
                    <p>Если это ваш номер, <Link to={LOGIN}>Войдите</Link></p>
                </Form.Item>
            }
            <Form.Item name={'birthday'} label={'Дата рождения'}
                       rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <DatePicker format={'DD.MM.YYYY'}/>
            </Form.Item>
            <Form.Item name={'sex'} label={'Пол'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Radio.Group options={options} optionType="button"/>
            </Form.Item>
            <Form.Item name={'city'} label={'Город'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <AutoComplete placeholder={'Астана'} options={citiesOptions} onSearch={(value) => setSearch(value)}/>
            </Form.Item>

            <Form.Item>
                <Checkbox checked={agreePolicy} onChange={() => setAgreePolicy(!agreePolicy)}>Согласен c <a href={DOC_POLICY}
                                                                                                     rel={'noreferrer'}
                                                                                                     target={'_blank'}>политикой обработки персональных
                    данных</a></Checkbox>
                <Checkbox checked={agreeOffer} onChange={() => setAgreeOffer(!agreeOffer)}>Согласен c <a href={DOC_OFFER}
                                                                                                     rel={'noreferrer'}
                                                                                                     target={'_blank'}>правилами и условиями оферты</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!(agreeOffer && agreePolicy)} icon={<RightOutlined/>} loading={createRegData.loading || createCodeData.loading}>Получить
                    код</Button>
            </Form.Item>

        </Form>
    )
})
