import {useEffect, useState} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {RightOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import { Button, Form} from 'antd'
import {MaskedInput} from 'antd-mask-input';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';

import {REGISTRATION} from '../../config/routes';
import {Store} from '../../model/store/Store';
import {
    createGetMoneyKzCode,
    createGetMoneyKzCodeVariables
} from '../../shared/graphql/__generated__/createGetMoneyKzCode';
import {CREATE_CODE} from '../../shared/graphql/createGetMoneyKzCode';
import {phoneKzRegex} from '../../shared/helpers/regex';

import cls from '../registration/Registration.module.scss'

interface IFormValues {
    phone: string;
}

interface IForm {

    onComplete: (values: IFormValues) => void

}

export const LoginForm = observer(({onComplete}: IForm) => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const {auth} = useInstance(Store)

    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const awaitSeconds = dayjs(auth.SendingDate).diff(dayjs().add(-5, 'minutes'), 'seconds')
    console.log(time)

    const [createCode] = useMutation<createGetMoneyKzCode, createGetMoneyKzCodeVariables>(CREATE_CODE)

    const onFinish = async (values: IFormValues) => {
        await createCode({
            variables: {
                phone: values.phone,
                data: {},
            },
            onCompleted: () => {
                auth.setData(null, values.phone, dayjs().format(), null)
                onComplete(values)
            },
            onError: (error) => {
                if (error.message === 'Unknown phone number') {
                    navigate(REGISTRATION)
                }
                if (error.message === 'Await') {
                    auth.setData(null, values.phone, dayjs().format(), null)
                }
            }
        })
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}
              className={cls.form}>

            <Form.Item name={'phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneKzRegex, message: 'Не соответствует формату номера телефона'}
            ]}
            >
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (700) 000-00-00'} size={'large'}/>
            </Form.Item>


            {awaitSeconds > 0 &&
                <Form.Item>
                    Отправить повторно через {Math.floor(awaitSeconds/60)}:{awaitSeconds%60}
                </Form.Item>
            }

            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} icon={<RightOutlined/>}>Получить код</Button>
            </Form.Item>

        </Form>
    )

})