import cn from 'classnames';

import cls from './Advantages.module.scss'

interface IBlock {

    img: string,
    title: string,
    text: string,
}

export const Block = ({img, title, text}: IBlock) => (
    <div className={cn([cls.block])}>
        <div className={'bold'}>{title}</div>
        <div className={cls.text}>{text}</div>
        <div className={cls.image}>
            <img src={img} alt={'Character'}/>
        </div>
    </div>
)