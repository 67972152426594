import React, { useState} from 'react';
import {Link,useNavigate } from 'react-router-dom';
import cn from 'classnames'

import {INDEX, REGISTRATION} from '../../config/routes';
import {Code} from '../registration/Code';

import { LoginForm } from './LoginForm';

import cls from './Login.module.scss'

export const Login = () => {

    const [step, setStep] = useState<number>(0)
    const navigate = useNavigate()

    return (
        <>
            <div className={cn([cls.page, 'content'])}>

                <h2>Вход</h2>

                {step === 0 && <LoginForm onComplete={() => setStep(1)}/>}
                {step === 1 && <Code onComplete={() => navigate(INDEX)} onBack={() => setStep(0)}/>}

                <p>
                    Ещё нет аккаунта? <Link to={REGISTRATION}>Зарегистрируйтесь</Link>
                </p>

            </div>
        </>
    );
}


