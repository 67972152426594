import {gql} from '@apollo/client';

export const CREATE_REG = gql`
    mutation createGetMoneyKzReg ($data: GetMoneyKzRegInput!, $session: String) {
        createGetMoneyKzReg(data: $data, session: $session) {
            data {
                id
            }
        }
    }
`