// INDEX
export const ROOT = '';
export const INDEX = '/';
export const REGISTRATION = '/registration';
export const LOGIN = '/login';
export const OFFERS = '/offers/SDR8KzArwCTW9Vy6Q7excL';
export const CODE = '/code';
export const DONE = '/done';
export const PAYMENT = '/payment';
export const PAYMENT_UPDATE = '/payment/update/:UID';
export const DOC_POLICY = '/docs/Политика_обработки_персональных_данные_АйТи_Консул.pdf';
export const DOC_OFFER = '/docs/Оферта_платный_подбор_займа_АйТи_Консул.pdf';
export const NOT_FOUND = '/404';

