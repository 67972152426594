import {Block} from './Block';
import deal from './deal.png'
import device from './device.png'
import pot from './pot.png'

import cls from './Advantages.module.scss'

export const Advantages = () => (
    <div className={cls.instruction}>
        <h2>Наши преимущества</h2>
        <div className={cls.blocks}>
            <Block img={device} title={'С любого устройства'} text={'Быстрый доступ к займу с любого устройства'}/>
            <Block img={pot} title={'Работаем 24/7'} text={'Мы доступны для вас в любое время дня и ночи'}/>
            <Block img={deal} title={'Моментальное решение'} text={'Быстрый способ решить проблему'}/>
        </div>
    </div>
)