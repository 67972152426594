import {Button} from 'antd';

import cards from './cards.png'

import cls from './GetMoney.module.scss'

export const GetMoney = () => (
    <div className={cls.block}>
        <div className={cls.content}>
            <h2>Получите деньги</h2>
            <div>
                Услуга оплаты на сайте GetMoney осуществляется в соответствии с Правилами международных платежных систем Visa и MasterCard на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице FreedomPay. Никто, в том числе компания ТОО «АйТи Консул» (https://togetmoney.kz), не может получить банковские и персональные данные плательщика.
            </div>
            <div className={cls.control}>
                    <Button size={'large'}>Реквезиты</Button>
                    <Button size={'large'}>Войти</Button>
            </div>
        </div>
        <div className={cls.image}>
            <img src={cards} alt={'Cards'}/>
        </div>
    </div>
)