
import rainbow from './rainbow.png'

import cls from './Instruction.module.scss'

export const Instruction = () => (
    <div className={cls.instruction}>
        <div className={cls.content}>
            <h2>Как это работает?</h2>
            <div className={'bold'}>
                Get Money —
                Это удобный и быстрый способ получения финансовой помощи.
            </div>
            <div>
                <img src={rainbow} alt={'Rainbow'}/>
            </div>

        </div>
        <div className={cls.steps}>
            <div className={cls.step}>
                <div className={cls.title}>Заполните анкету</div>
                <div className={cls.number}>1</div>
            </div>
            <div className={cls.step}>
                <div className={cls.title}>Дождитесь одобрения</div>
                <div className={cls.number}>2</div>
            </div>
            <div className={cls.step}>
                <div className={cls.title}>Получите деньги</div>
                <div className={cls.number}>3</div>
            </div>

        </div>
    </div>
)