import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Slider} from 'antd';
import cn from 'classnames';
import currency from 'currency.js'
import dayjs from 'dayjs';

import {REGISTRATION} from '../../../config/routes';

import img from './banner.jpg';

import cls from './Banner.module.scss'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const CurrencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const Banner = () => {

    const [zaim,setZaim] = useState<number>(85000)
    const [term,setTerm] = useState<number>(15)
    const navigate = useNavigate()

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={cls.banner} style={{backgroundImage: `url(${width > 768 ? img : undefined})`}}>

            <div className={cn(['highlight', cls.title])}>
                <div className={'bold'}>Get Money —</div>
                <div>мгновенные онлайн займы, с&nbsp;любой кредитной историей</div>
            </div>

            <div className={cls.form}>
                <div>
                    <div className={cn([cls.label, 'bold'])}>
                        <div>Сумма займа</div>
                        <div>{CurrencyFormat(zaim)} ₸</div>
                    </div>
                    <Slider value={zaim} onChange={(value) => setZaim(value)} min={10000} max={200000} step={1000}/>
                    <div className={cn([cls.label, 'caption'])}>
                        <div>10&nbsp;000&nbsp;₸</div>
                        <div>200&nbsp;000&nbsp;₸</div>
                    </div>
                </div>

                <div>
                    <div className={cn([cls.label, 'bold'])}>
                        <div>Срок займа</div>
                        <div>{term}</div>
                    </div>
                    <Slider value={term} onChange={(value) => setTerm(value)} min={1} max={30} step={1}/>
                    <div className={cn([cls.label, 'caption'])}>
                        <div>1&nbsp;День</div>
                        <div>30&nbsp;Дней</div>
                    </div>
                </div>

                <div>
                    <div className={cn([cls.label, 'bold'])}>
                        <div>Возвращаете</div>
                    </div>
                    <div>
                        <span className={cls.sum}>{CurrencyFormat(zaim + (zaim * term * 0.0008))} ₸</span> до {dayjs().add(term, 'day').format('DD.MM.YYYY')}
                    </div>
                </div>

                <div><Button type={'primary'} size={'large'} onClick={() => navigate(REGISTRATION)} block>Оформить заём</Button></div>

            </div>



        </div>
    )
}