import {gql} from '@apollo/client';

export const CREATE_ORDER = gql`
    mutation createGetMoneyKzOrder ($data: GetMoneyKzOrderInput!, $token: String!, $session: String) {
        createGetMoneyKzOrder(data: $data, token: $token, session: $session) {
            data {
                id
                attributes {
                    UID
                    paymentURL
                }
            }
        }
    }
`