import {useEffect} from 'react';
import {useInstance} from 'react-ioc';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import {observer} from 'mobx-react-lite';

import {ENUM_GETMONEYKZORDER_STATUS} from '../../__generated__/global-types';
import {INDEX} from '../../config/routes';
import {Store} from '../../model/store/Store';
import {getMoneyKzOrder, getMoneyKzOrderVariables} from '../../shared/graphql/__generated__/getMoneyKzOrder';
import {QUERY_ORDER} from '../../shared/graphql/order';

export const OrderUpdate = observer(() => {

    const {UID} = useParams()
    const navigate = useNavigate()
    const {auth} = useInstance(Store)

    const order = useQuery<getMoneyKzOrder, getMoneyKzOrderVariables>(QUERY_ORDER, {
        variables: {
            UID: UID!,
            token: auth.token!,
        },
        pollInterval: 5000,
        skip: auth.token === null,
    })

    useEffect(() => {
        const status = order.data?.getMoneyKzOrder?.data?.attributes?.status
        // console.log('status', status)
        if (status === ENUM_GETMONEYKZORDER_STATUS.PAID_3 || status === ENUM_GETMONEYKZORDER_STATUS.ERROR_4) {
            navigate(INDEX)
        }
    }, [navigate, order.data?.getMoneyKzOrder?.data?.attributes?.status])

    return (
        <div className={'content'}>
            <Skeleton active/>
        </div>
    )
})