import React, {FC, useEffect, useState} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import { Steps} from 'antd';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';

import {INDEX} from '../../config/routes';
import {Store} from '../../model/store/Store';
import {Order} from '../Order/Order';

import {Code} from './Code';
import { Done } from './Done';
import { RegForm } from './RegForm';

import cls from './Registration.module.scss'

export const Registration:FC = observer(() => {

    const {auth} =  useInstance(Store)
    const navigate = useNavigate()
    const [step, setStep] = useState<number>(auth.token === null ? 0 : 2)

    useEffect(() => {
        if (auth.token !== null) {
            if (auth.paid) {
                navigate(INDEX)
            } else {
                setStep(2)
            }
        } else {
            setStep(0)
        }
    }, [auth.paid, auth.token, navigate])

    return (
        <>
            <div className={cn([cls.registration, 'content'])}>

                <h2>Регистрация</h2>

                <Steps
                    current={step}
                    items={[
                        {
                            title: 'Заполните форму',
                        },
                        {
                            title: 'Введите код',
                        },
                        {
                            title: 'Оплатите',
                        },
                        {
                            title: 'Готово',
                        },
                    ]}
                />

                {/*{step === 0 && <Code onComplete={() => setStep(2)} onBack={() => setStep(0)}/>}*/}

                {step === 0 && <RegForm onComplete={() => setStep(1)}/>}
                {step === 1 && <Code onComplete={() => setStep(2)} onBack={() => setStep(0)}/>}
                {step === 2 && <Order/>}
                {step === 3 && <Done/>}


            </div>
        </>
    );
})


