import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button} from 'antd';
import {observer} from 'mobx-react-lite';

import {INDEX} from '../../config/routes';
import {getSession} from '../../hooks/getSession';
import {Store} from '../../model/store/Store';
import {
    createGetMoneyKzOrder,
    createGetMoneyKzOrderVariables
} from '../../shared/graphql/__generated__/createGetMoneyKzOrder';
import {CREATE_ORDER} from '../../shared/graphql/createGetMoneyKzOrder';

import cls from './Payment.module.scss'

export const Payment = observer(() => {

    const navigate = useNavigate()
    const {auth} = useInstance(Store)
    const [createOrder, {loading}] = useMutation<createGetMoneyKzOrder,createGetMoneyKzOrderVariables>(CREATE_ORDER)

    const onCreateOrder = async () => {
        await createOrder({
            variables: {
                session: getSession(),
                token: auth.token!,
                data: {},
            },
            onCompleted: (data) => {
                const paymentURL = data.createGetMoneyKzOrder?.data?.attributes?.paymentURL!
                window.location.assign(paymentURL);
            }
        })
    }

    return (
        <div className={cls.wrapper}>
            <h2>Внесите оплату для доступа к полному функционалу платформы</h2>
            <div className={cls.row}>
                <div>
                    Услуги Оператора
                </div>
                <div className={cls.sum}>
                    1 750 ₸
                </div>
            </div>
            <div className={cls.control}>
                <Button size={'large'} icon={<HomeOutlined/>} onClick={() => navigate(INDEX)}/>
                <Button type={'primary'} size={'large'} icon={<RightOutlined/>} onClick={onCreateOrder} loading={loading}>Перейти к оплате</Button>
            </div>
        </div>
    )
})