/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_GETMONEYKZCODE_STATUS {
  CREATED_1 = "CREATED_1",
  ERROR_4 = "ERROR_4",
  SENDED_2 = "SENDED_2",
  SUCCESS_3 = "SUCCESS_3",
}

export enum ENUM_GETMONEYKZORDER_STATUS {
  AWAIT_2 = "AWAIT_2",
  CREATED_1 = "CREATED_1",
  ERROR_4 = "ERROR_4",
  PAID_3 = "PAID_3",
  REFUND_5 = "REFUND_5",
}

export enum ENUM_GETMONEYKZREG_SEX {
  female = "female",
  male = "male",
}

export interface CheckCodeGetMoneyKZInput {
  phone: string;
  code: string;
  click_id?: string | null;
}

export interface CityKzFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  localizations?: CityKzFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (CityKzFiltersInput | null)[] | null;
  or?: (CityKzFiltersInput | null)[] | null;
  not?: CityKzFiltersInput | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface GetMoneyKzCodeInput {
  code?: string | null;
  status?: ENUM_GETMONEYKZCODE_STATUS | null;
  token?: string | null;
  user?: string | null;
}

export interface GetMoneyKzOrderInput {
  UID?: string | null;
  user?: string | null;
  status?: ENUM_GETMONEYKZORDER_STATUS | null;
  paidAt?: any | null;
  paymentObject?: any | null;
  paymentURL?: string | null;
  paymentId?: string | null;
  callbackObject?: any | null;
  statusObject?: any | null;
  test?: boolean | null;
  refundAt?: any | null;
}

export interface GetMoneyKzRegInput {
  FIO?: string | null;
  phone?: string | null;
  birthday?: any | null;
  sex?: ENUM_GETMONEYKZREG_SEX | null;
  city?: string | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
