import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialAuthStore = {
    token: null,
    auth: null,
    FIO: null,
    phone: null,
    SendingDate: null,
    paid: null,
};

export const AuthStore = t
    .model({
        token: t.maybeNull(t.string),
        auth: t.maybeNull(t.boolean),
        FIO: t.maybeNull(t.string),
        phone: t.maybeNull(t.string),
        SendingDate: t.maybeNull(t.string),
        paid: t.maybeNull(t.boolean),
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialAuthStore);
        },
        setAuth: (auth: boolean | null, token: string) => {
            self.auth = auth;
            self.token = token;
        },
        setData: (FIO: string | null, phone: string | null, SendingDate: string | null, paid: boolean | null) => {
            self.FIO = FIO;
            self.phone = phone;
            self.SendingDate = SendingDate
            self.paid = paid
        },
    }));
